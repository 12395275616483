type SessionStorageItem = 'enableThemeConfigurator'

export const useSessionStorage = () => {
  const setItem = (key: SessionStorageItem, value: string) => {
    sessionStorage.setItem(key, value)
  }

  const getItem = (key: SessionStorageItem) => {
    const value = sessionStorage.getItem(key)

    if (value === 'false') return false

    return value
  }

  return {
    getItem,
    setItem
  }
}
