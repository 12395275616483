<template>
  <label class="relative flex w-full flex-col">
    <slot name="label" />
    <span class="relative">
      <slot name="prepend" />
      <input
        v-bind="{ ...$attrs }"
        ref="inputElement"
        :value="modelValue"
        class="w-full bg-primary text-base outline-0 placeholder:text-quaternary disabled:opacity-50"
        :class="[severityClassesMap[severity], { '!border-error': isInvalidInput }]"
        :type="type"
        @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        @change="emit('change', ($event.target as HTMLInputElement).value)"
      />
      <slot v-if="type !== 'search' || (type === 'search' && !modelValue)" name="append" />
      <button
        v-if="type === 'search' && modelValue"
        type="button"
        class="absolute right-6 flex size-5 items-center justify-center rounded-full bg-icon-tertiary text-white inset-y-center"
        @click="emit('update:modelValue', '')"
      >
        <UiIcon name="x-mark" class="size-4" />
      </button>
    </span>
    <TransitionFade :duration="200">
      <span v-show="isInvalidInput" class="text-xs text-error">{{ errors[0] }}</span>
    </TransitionFade>
  </label>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import type { Nullable } from 'ts-helpers'
import { computed, ref } from 'vue'
import UiIcon from './UiIcon.vue'

type PropType = {
  modelValue: string | number
  invalid?: boolean
  severity?: 'primary' | 'secondary' | 'tertiary'
  type?: 'text' | 'search' | 'email' | 'number'
  errors?: string[]
}

type EmitType = {
  (e: 'update:modelValue', value: string): void
  (e: 'change', value: string): void
}

const props = withDefaults(defineProps<PropType>(), {
  invalid: false,
  severity: 'primary',
  type: 'text',
  errors: () => []
})

const emit = defineEmits<EmitType>()

defineOptions({
  inheritAttrs: false
})

const isInvalidInput = computed(() => props.errors.length)

const severityClassesMap: Record<NonNullable<PropType['severity']>, string> = {
  primary: 'rounded-[60px] px-4 py-3 border-4 border-secondary focus:border-primary',
  secondary: 'rounded-lg border p-3 border-secondary focus:border-primary',
  tertiary: 'border border-transparent focus:border-secondary p-3'
}

const inputElement = ref<Nullable<HTMLInputElement>>(null)

defineExpose({
  inputElement
})
</script>
