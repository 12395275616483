import type { ErrorCodes } from '@/6_shared/api'
import type { Theme } from '../model'
import { themeConstructor, ThemeConstructor } from '../model/theme/useTheme.ts'

export enum AppMessengerPostMessageNames {
  open = 'open',
  close = 'close',
  navigate = 'navigate',
  widgetOpen = 'widget:open',
  widgetClose = 'widget:close',
  widgetOrder = 'widget:order',
  errorIntegration = 'error:integration',
  errorNotFound = 'error:notFound',
  themeInit = 'theme:init'
}

export enum AppMessengerGetMessageNames {
  newTheme = 'newTheme',
  themeHighlightOn = 'theme:highlightOn',
  themeHighlightOff = 'theme:highlightOff'
}

class AppMessenger {
  private readonly _messagePrefix = 'inticketsAfisha:'
  private port?: MessagePort

  constructor() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data === 'capturePort' && event.ports[0]) {
        this.port = event.ports[0]
      }
    })
  }

  private _createMessage = (messageName: AppMessengerPostMessageNames, params?: object) => {
    const message = JSON.stringify({ name: `${this._messagePrefix}${messageName}`, params })

    this.port ? this.port.postMessage(message) : parent.postMessage(message, '*')
  }

  private _createOnMessageHandlers = () => {
    this.port &&
      (this.port.onmessage = (e) => {
        this.theme.themeUpdateHandler(e)
        this.theme.highlightElementsHandler(e)
        this.theme.offHighlightsElements(e)
      })
  }

  public open() {
    this._createMessage(AppMessengerPostMessageNames.open)
    this.theme.init()
    this._createOnMessageHandlers()
  }

  public close() {
    return this._createMessage(AppMessengerPostMessageNames.close)
  }

  public navigate(routeName: string) {
    return this._createMessage(AppMessengerPostMessageNames.navigate, { routeName })
  }

  public widget = {
    open: () => this._createMessage(AppMessengerPostMessageNames.widgetOpen),
    close: () => this._createMessage(AppMessengerPostMessageNames.widgetClose),
    order: () => this._createMessage(AppMessengerPostMessageNames.widgetOrder)
  }

  public error = {
    integration: {
      partnerKey: () =>
        this._createMessage(AppMessengerPostMessageNames.errorIntegration, {
          message: 'partner key not set or invalid'
        }),
      custom: (content: object) =>
        this._createMessage(AppMessengerPostMessageNames.errorNotFound, content)
    },
    http: {
      notFound: (code: ErrorCodes, url: string) =>
        this._createMessage(AppMessengerPostMessageNames.errorNotFound, { code, url })
    }
  }

  public theme = {
    /**
     * постит установленную тему
     */
    init: () => {
      this._createMessage(AppMessengerPostMessageNames.themeInit, {
        theme: ThemeConstructor.getThemeFromDocumentElement()
      })
    },
    /**
     * создает обработчик изменения полной темы
     */
    themeUpdateHandler: (e: MessageEvent) => {
      try {
        const data = JSON.parse(e.data)

        data.type === AppMessengerGetMessageNames.newTheme &&
          themeConstructor.setTheme(data.theme as Theme)
      } catch (e) {
        console.log('failed to set theme ', e)
      }
    },
    /**
     * подсвечивает элементы
     * @param e
     */
    highlightElementsHandler: (e: MessageEvent) => {
      try {
        const data = JSON.parse(e.data)

        data.type === AppMessengerGetMessageNames.themeHighlightOn &&
          ThemeConstructor.highlightProp(data.element as string)
      } catch (e) {
        console.log('failed to set theme ', e)
      }
    },
    /**
     * убирает подсветку
     * @param e
     */
    offHighlightsElements: (e: MessageEvent) => {
      try {
        const data = JSON.parse(e.data)

        data.type === AppMessengerGetMessageNames.themeHighlightOff &&
          ThemeConstructor.offHighlight()
      } catch (e) {
        console.log('failed to set theme ', e)
      }
    }
  }
}

export const appMessenger = new AppMessenger()
