import type { Theme } from '../../model'

export const darkTheme: Theme = {
  colors: {
    // основная тема
    primary: '#1b65f5',
    secondary: '#FFFFFF',
    tertiary: '#C1C7D1',
    quaternary: '#B1BACC',

    // текста
    text: {
      DEFAULT: '#FFFFFF',
      primary: '#1B65F5',
      secondary: '#727985',
      tertiary: '#A1A8B5',
      quaternary: '#C1C7D1',
      fivefold: '#B1BACC',
      error: '#FF3b30',
      success: '#32d74b'
    },
    calendar: {
      text: {
        inactive: '#D2D9E6',
        primary: '#233353',
        weekend: '#EF413D',
        active: '#1B65F5'
      },
      button: {
        primary: '#1B65F5',
        secondary: '#F5F7FF',
        secondaryHover: '#dbe2fe'
      }
    },
    button: {
      primary: {
        DEFAULT: '#1B65F5',
        hover: '#185ADA',
        text: '#fff'
      },
      secondary: {
        DEFAULT: '#F0F2F8',
        hover: '#E6EAF3',
        text: '#233353'
      },
      tertiary: {
        DEFAULT: '#F2F2F2',
        hover: '#dcdcdc',
        text: '#233353'
      },
      disabled: '#D6D6D6',
      disabledText: '#fff'
    },

    badge: {
      primary: {
        DEFAULT: '#1B65F5',
        text: '#fff'
      },
      secondary: {
        DEFAULT: '#FFFFFF',
        text: '#233353'
      },
      tertiary: {
        DEFAULT: 'rgba(0,0,0,0.7)',
        text: '#FFF'
      }
    },

    card: {
      primary: '#FFFFFF',
      secondary: '#F0F2F8',
      tertiary: '#233353',
      quaternary: '#F5E8F4',
      fivefold: '#F5EEE8',
      sixfold: '#EAE8F5',
      septenary: '#EAF5E8',
      octal: '#F5E8E8',
      ninefold: '#E8F5F4'
    },

    border: {
      primary: '#B1BACC',
      secondary: '#F0F2F8',
      tertiary: '#99a2bb'
    },

    icon: {
      primary: '#007AFF',
      secondary: '#233353',
      tertiary: '#FFFFFF',
      disabled: '#FFFFFF'
    },
    // противоположная тема
    side: {
      text: {
        DEFAULT: '#FFFFFF',
        secondary: '#C1C7D1',
        tertiary: '#A1A8B5'
      }
    }
  },
  backgroundColor: {
    primary: '#0C0722',
    secondary: '#0C0722',
    tertiary: '#0C0722',
    overlay: 'rgba(0,0,0,0.3)',
    side: {
      primary: '#FFFFFF'
    }
  },
  boxShadow: {
    // badge
    primary: '0 8px 16px rgba(27, 101, 245, 0.4)',
    // badge
    secondary: '0 8px 16px rgba(179, 195, 225, 0.4)',
    // модальные окна
    secondary_lg: '0 20px 25px rgba(51,65,85, .1), 0 10px 10px rgba(51,65,85,.04)',
    // поисковая форма
    tertiary: '0 4px 6px rgba(119, 119, 119, 0.05)'
  },
  boxShadowColor: ({ theme }) => theme('color'),
  fontFamily: {
    sans: 'Inter'
  }
}
