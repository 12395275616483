import { ofetch } from 'ofetch'
import { useRuntimeConfig } from '../../config'
import { LocalStorageNames, useLocalStorage } from '../../lib'

const createHeaders = (): HeadersInit => {
  const headers: HeadersInit = {}
  const authToken = useLocalStorage().get(LocalStorageNames.authToken)

  if (authToken) headers['Authorization'] = `Bearer ${authToken}`

  return headers
}

export const http = ofetch.create({
  baseURL: import.meta.env.PROD ? `${useRuntimeConfig()?.VITE_APP_BASE_URL}api/` : '/api/',
  onRequest({ options }) {
    options.headers = createHeaders()
  }
})
