export enum LocalStorageNames {
  authToken = 'userToken',
  partnerKey = 'partnerKey'
}

export const useLocalStorage = () => {
  const set = (name: LocalStorageNames, value: string) => localStorage.setItem(name, value)
  const get = (name: LocalStorageNames) => localStorage.getItem(name)
  const remove = (name: LocalStorageNames) => localStorage.removeItem(name)
  const clear = () => localStorage.clear()

  return {
    get,
    set,
    remove,
    clear
  }
}
