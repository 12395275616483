<template>
  <div
    class="w-fit rounded-lg px-2 py-1.5 text-xs font-bold"
    :class="[computedClasses, { absolute: positionX || positionY }]"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type PropType = {
  severity?: 'primary' | 'secondary' | 'tertiary'
  positionY?: 'top' | 'bottom' | 'center' | ''
  positionX?: 'left' | 'right' | 'center' | ''
}

const props = withDefaults(defineProps<PropType>(), {
  severity: 'primary',
  positionX: '',
  positionY: ''
})

const severityClassesMap: Record<NonNullable<PropType['severity']>, string> = {
  primary: 'text-badge-primary-text bg-badge-primary shadow-primary',
  secondary: 'text-badge-secondary-text bg-badge-secondary shadow-secondary',
  tertiary: 'text-badge-tertiary-text bg-badge-tertiary font-normal'
}
const positionXClassesMap: Record<NonNullable<PropType['positionX']>, string> = {
  left: 'left-0 right-auto',
  center: 'inset-x-center',
  right: 'right-0 left-auto',
  '': ''
}
const positionYClassesMap: Record<NonNullable<PropType['positionY']>, string> = {
  top: 'top-0 bottom-auto',
  center: 'inset-y-center',
  bottom: 'top-auto bottom-0',
  '': ''
}

const computedClasses = computed(() =>
  [
    severityClassesMap[props.severity],
    positionXClassesMap[props.positionX],
    positionYClassesMap[props.positionY]
  ].join(' ')
)
</script>
