import { parseQuery } from 'ufo'
import { getElementByGlobalId, GlobalIds } from '@/6_shared/config'
import { LocalStorageNames, useLocalStorage } from '@/6_shared/lib'

export const partnerKey = {
  get(): string {
    return useLocalStorage().get(LocalStorageNames.partnerKey) || ''
  },
  set(partnerKey: string) {
    useLocalStorage().set(LocalStorageNames.partnerKey, partnerKey)
  },
  clear() {
    useLocalStorage().remove(LocalStorageNames.partnerKey)
  },
  handleEmpty() {
    location.replace('/errors/partnerId')
  }
}

export const disableGlobalLoader = () => {
  getElementByGlobalId(GlobalIds.globalAppLoader)!.style.display = 'none'
}

export const getParsedInitialParamsFromQuery = () => parseQuery(location.search)
