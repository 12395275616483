<template>
  <label>
    <slot name="label" />
    <ColorPicker
      :pure-color="modelValue"
      type="color"
      @update:pure-color="emit('update:modelValue', $event)"
    />
  </label>
</template>

<script setup lang="ts">
import { ColorPicker } from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'

type PropType = {
  modelValue: string
}
type EmitType = {
  (e: 'update:modelValue', value: string): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()
</script>
