<template>
  <template v-if="src && !imageNotLoaded">
    <img
      v-if="src && isFileFormatSvg"
      v-bind="{ src, ...$attrs }"
      :alt="($attrs.alt as string) || src"
      :class="$attrs.class"
      class="bg-tertiary"
      :loading="lazy ? 'lazy' : undefined"
    />
    <picture v-else-if="src && !isProxyLoadError" :class="$attrs.class" class="bg-tertiary">
      <source :srcset="pictureUrl.webp" type="image/webp" />
      <img
        v-bind="{ src, ...$attrs }"
        :loading="lazy ? 'lazy' : undefined"
        :alt="($attrs.alt as string) || src"
        @error="onProxyImageLoadError"
      />
    </picture>
    <img
      v-else
      class="bg-tertiary"
      v-bind="{ src, ...$attrs }"
      :class="$attrs.class"
      :alt="($attrs.alt as string) || src"
      @error="onImageLoadError"
    />
  </template>
  <slot v-else name="default-image">
    <img
      :src="`/${defaultName}.jpg`"
      class="bg-tertiary object-cover"
      v-bind="{ ...$attrs }"
      alt="Не удалось загрузить изображение"
    />
  </slot>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ref } from 'vue'
import { useImgProxy } from '../composables'

type PropType = {
  src: string
  options?: any
  lazy?: boolean
  defaultName?: 'default' | 'default_venue'
}

const props = withDefaults(defineProps<PropType>(), {
  options: () => ({}),
  lazy: false,
  defaultName: 'default'
})

const imageNotLoaded = ref(false)
const isFileFormatSvg = computed(() => props.src?.split('.').pop() === 'svg')

const { getImageProxyUrl } = useImgProxy()
const pictureUrl = computed(() => {
  return {
    webp: props.src && getImageProxyUrl(props.src, props.options, 'webp')
  }
})

const isProxyLoadError = ref(false)

const onProxyImageLoadError = () => {
  isProxyLoadError.value = true
}

const onImageLoadError = () => {
  imageNotLoaded.value = true
}
</script>
