import type { Theme } from '../../model'

export const defaultTheme: Theme = {
  colors: {
    // основная тема
    primary: '#1b65f5',
    secondary: '#233353',
    tertiary: '#727985',
    quaternary: '#B1BACC',
    fivefold: '#F0F2F8',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    text: {
      main: '#233353',
      primary: '#1B65F5',
      secondary: '#727985',
      tertiary: '#A1A8B5',
      quaternary: '#C1C7D1',
      fivefold: '#B1BACC'
    },

    calendar: {
      text: {
        inactive: '#D2D9E6',
        primary: '#233353',
        weekend: '#EF413D',
        active: '#1B65F5'
      }
    },

    button: {
      primary: {
        text: '#fff',
        link: {
          DEFAULT: '#1B65F5',
          hover: 'rgba(70,171,152,1)'
        }
      },
      secondary: {
        text: '#1B65F5',
        link: {
          DEFAULT: '#727985',
          hover: 'rgba(70,171,152,1)'
        }
      },
      tertiary: {
        text: '#233353',
        link: {
          DEFAULT: '#727985',
          hover: 'rgba(70,171,152,1)'
        }
      }
    },

    link: {
      DEFAULT: '#b1bacc',
      hover: '#185ada',
      disabled: '#d6d6d6'
    },

    checkbox: {
      icon: '#fff'
    },

    badge: {
      primary: {
        text: '#fff'
      },
      secondary: {
        text: '#233353'
      },
      tertiary: {
        text: '#233353'
      }
    },

    icon: {
      primary: '#007AFF',
      secondary: '#233353',
      tertiary: '#716E6E',
      quaternary: '#B1BACC',
      disabled: '#716e6e',
      error: '#FF3b30',
      success: '#1eb752'
    },

    gradient: {
      primary: {
        from: '#F0F2F8',
        to: '#E0EDFF'
      },
      secondary: {
        from: 'rgba(244,247,253,0)',
        to: '#F3F7FE'
      },
      card_circle: {
        first: 'rgba(249,227,176,0.3)',
        second: 'rgba(255,196,171,0.3)',
        third: 'rgba(236,180,208,0.3)',
        fourth: 'rgba(185,180,236,0.3)'
      }
    },

    // противоположная тема
    side: {
      dark: '#fff',
      light: '#000',
      text: {
        DEFAULT: '#FFFFFF',
        secondary: '#C1C7D1',
        tertiary: '#A1A8B5'
      }
    }
  },
  backgroundColor: {
    main: '#1B65F5',
    primary: '#FFF',
    secondary: '#F0F2F8',
    tertiary: '#EFF3F8',
    overlay: 'rgba(0,0,0,0.3)',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',
    button: {
      primary: {
        DEFAULT: '#1B65F5',
        hover: '#185ADA',
        disabled: '#d6d6d6'
      },
      secondary: {
        DEFAULT: '#F0F2F8',
        hover: '#E6EAF3',
        disabled: '#f2f2f2'
      },
      tertiary: {
        DEFAULT: '#F2F2F2',
        hover: '#dcdcdc',
        disabled: '#f2f2f2'
      }
    },
    badge: {
      primary: {
        DEFAULT: '#1B65F5'
      },
      secondary: {
        DEFAULT: '#FFFFFF'
      },
      tertiary: {
        DEFAULT: '#F0F2F8'
      }
    },
    card: {
      primary: {
        DEFAULT: '#FFFFFF',
        hover: '#FFFFFF'
      },
      secondary: {
        DEFAULT: '#F0F2F8',
        hover: '#F0F2F8'
      },
      tertiary: {
        DEFAULT: '#233353',
        hover: '#233353'
      },
      quaternary: {
        DEFAULT: '#F5E8F4',
        hover: '#E2D6E1'
      },
      fivefold: {
        DEFAULT: '#F5EEE8',
        hover: '#E2DBD6'
      },
      sixfold: {
        DEFAULT: '#EAE8F5',
        hover: '#D8D6E2'
      },
      septenary: {
        DEFAULT: '#EAF5E8',
        hover: '#D8E2D6'
      },
      octal: {
        DEFAULT: '#F5E8E8',
        hover: '#E2D6D6'
      },
      ninefold: {
        DEFAULT: '#E8F5F4',
        hover: '#D6E2E1'
      }
    },
    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    calendar: {
      range: '#F5F7FF',
      active: '#1B65F5'
    },
    side: {
      primary: '#0C0722'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    }
  },
  borderColor: {
    primary: '#B1BACC',
    secondary: '#F0F2F8',
    tertiary: '#99a2bb',
    quaternary: '#233353',
    fivefold: '#E0E7F1',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',
    card: {
      primary: {
        DEFAULT: 'rgba(224,231,241,0.75)',
        hover: 'rgba(224,231,241,1)',
        focus: 'rgba(24,90,218,1)'
      }
    },
    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    }
  },
  boxShadow: {
    // badge-main
    primary: '0 8px 16px 0px rgba(27,101,245,0.4)',
    // badge-secondary
    secondary: '0 8px 16px 0px rgba(179,195,225,0.4)',
    // модальные окна
    secondary_lg: '0 20px 25px 0px rgba(51,65,85,.1), 0 10px 10px 0px rgba(51,65,85,.04)',
    // поисковая форма
    tertiary: '0 4px 6px 0px rgba(119,119,119,0.05)',
    // каретки слайдера
    quaternary: '0 4px 12px 0px rgba(27,101,245,0.4)',
    poster_primary: '0 8px 20px 0px rgba(35,51,83,0.2)',
    card_primary: '0px 4px 4px 0px rgba(171,179,186,0.13)',
    card_primary_hover: '0px 4px 12px 0px rgba(171,179,186,0.20)'
  },
  fontFamily: {
    sans: 'Inter'
  }
}
