import cloneDeep from 'lodash/cloneDeep.js'
import isPlainObject from 'lodash/isPlainObject.js'
import merge from 'lodash/merge.js'
// eslint-disable-next-line
import { flattenObject } from '../../../../6_shared/lib/utils/flattenObject'
import { themes } from '../../config'
import type { MappedTheme, Theme } from './types'

export class ThemeConstructor {
  private readonly _defaultTheme
  private static _highlightClassName = 'highlight-element'

  constructor(defaultTheme: Theme) {
    this._defaultTheme = defaultTheme
  }

  public static offHighlight = () => {
    const activeElements = document.querySelectorAll(`.${this._highlightClassName}`)
    activeElements.forEach((item) => item.classList.remove(this._highlightClassName))
  }

  public static highlightProp = (prop: string) => {
    const addPropElementsHighlight = () => {
      const elements = document.documentElement.querySelectorAll(`*`)
      elements.forEach((item) => {
        if (item.classList.contains(prop) || item.classList.contains(`!${prop}`)) {
          item.classList.add(this._highlightClassName)
        }
      })
    }

    this.offHighlight()
    addPropElementsHighlight()
  }

  /**
   * получает тему из стилей <html>
   */
  public static getThemeFromDocumentElement = () => {
    return this.parseThemeFromStrings(this._getCssVariablesFromDocumentElement())
  }

  /**
   * возвращает набер имен и значений css переменных
   * @returns [string, string][]
   */
  private static _getCssVariablesFromDocumentElement = () => {
    const styles = Array.from(document.documentElement.style)

    return styles.reduce(
      (acc, style) => {
        const key = style.replace('--', '')
        const value = document.documentElement.style.getPropertyValue(style)
        acc.push([key, value])

        return acc
      },
      [] as [string, string][]
    )
  }

  /**
   * возвращает объект темы из массива [css-переменная, значение]
   * @param cssVariables
   * @returns [string, string][]
   */
  public static parseThemeFromStrings = (cssVariables: [string, string][]): Theme => {
    const theme: Theme = {}

    cssVariables.forEach(([variableName, variableValue]) => {
      const variableSplitted = variableName.split('-')

      const obj = variableSplitted.reduceRight((acc, item) => {
        const obj: any = {}
        obj[item] = acc

        return obj
      }, variableValue)

      merge(theme, obj)
    })

    return theme
  }

  /**
   * возвращает массив вида [css-переменная, значение] из темы
   * @param theme
   * @returns Theme
   */
  public static parseStringsFromTheme = (theme: Theme): [string, string][] => {
    const result: [string, string][] = []

    const getValue = (themeValue: any, sharedKey = '-') => {
      Object.entries(themeValue).forEach(([key, value]) => {
        const propertyKey = `${sharedKey}-${key}`

        if (isPlainObject(value)) {
          getValue(value, propertyKey)
        } else {
          result.push([propertyKey, value as string])
        }
      })
    }
    getValue(theme)

    return result
  }

  /**
   * отбрасывает лишние свойства в теме
   * @param theme
   */
  private _getGuardedTheme = (theme: Theme) => {
    const guardTheme: Theme = { ...theme }

    Object.keys(guardTheme).forEach((key) => {
      if (!(key in this._defaultTheme)) delete guardTheme[<keyof Theme>key]
    })

    return guardTheme
  }

  /**
   * возвращает объект вида
   * {css-variable-name}: value
   * @param theme
   * @private
   */
  private _mapTheme(theme: Theme): MappedTheme {
    return flattenObject<Theme>(this._getGuardedTheme(theme)) as MappedTheme
  }

  /**
   * устанавливает новые css переменные
   * @param themeObject
   * @private
   */
  private _setCssVariables(themeObject: MappedTheme) {
    const root = document.documentElement

    const setValue = (property: string, propertyValue: string) => {
      root.style.setProperty(`--${property}`, propertyValue)
    }

    Object.keys(themeObject).forEach((property) => {
      const value = themeObject[property]
      setValue(property, value)
    })
  }

  /**
   * создает из дефолтной темы объект с css переменными
   * {
   *   colors: {
   *     button: {
   *       primary: '#fff'
   *     }
   *   }
   * }
   * превратится в
   * {
   *   colors: {
   *     button: {
   *       primary: 'var(--colors-button-primary)'
   *     }
   *   }
   * }
   * @param object
   * @param sharedKey
   * @private
   */
  private _mapTailwindTemplateThemeVariables(object: any, sharedKey = '-') {
    Object.entries(object).forEach(([key, value]) => {
      const propertyKey = `${sharedKey}-${key}`

      if (isPlainObject(value)) {
        this._mapTailwindTemplateThemeVariables(value, propertyKey)
      } else {
        object[key] = `var(${propertyKey})`
      }
    })
  }

  /**
   * возврашает значения для tailwind
   */
  public get tailwindTheme() {
    const themeCopy = cloneDeep<Theme>(this._defaultTheme)

    this._mapTailwindTemplateThemeVariables(themeCopy)

    return {
      theme: { extend: themeCopy }
    }
  }

  /**
   * устанавливает дефолтную тему
   */
  public setDefaultTheme() {
    this.setTheme(this._defaultTheme)
  }

  /**
   * меняет тему
   * @param theme
   */
  public setTheme(theme: Theme | string): void {
    typeof theme === 'string' && (theme = JSON.parse(theme) as Theme)
    const themeObject = this._mapTheme(theme)

    this._setCssVariables(themeObject)
  }
}

const themeConstructor = new ThemeConstructor(themes.default)

export { themeConstructor }
